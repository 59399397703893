import React from "react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderMoneda } from "Utils/renderField/renderTableField";

const ProductosSubTabla = (row) => {
    return (
        <div className="border border-primary bg-blanco">
            <BootstrapTable
                data={row.detalles}
                remote={false}
                className="react-bs-table-container-creditos"
            >
                <TableHeaderColumn
                    dataField="codigo"
                    dataSort={false}
                    dataAlign="left"
                    width="10%"
                >
                    Codigo
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="producto"
                    dataSort={false}
                    width="50%"
                    isKey
                >
                    Descripcion
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="precio"
                    dataSort={false}
                    dataFormat={(cell, row) => {
                        const to_decimal = cell.toFixed(2);
                        return `Q ${to_decimal}`;
                    }}
                >
                    Costo
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="cantidad"
                    dataAlign="left"
                >
                    Cantidad
                </TableHeaderColumn>

                <TableHeaderColumn
                    dataField="sub_total"
                    dataSort={false}
                    dataAlign="left"
                    dataFormat={(cell, row) => {
                        const to_decimal = cell.toFixed(2);
                        return `Q ${to_decimal}`;
                    }}
                >
                    Subtotal
                </TableHeaderColumn>
            </BootstrapTable>
        </div>
    );
};

export default ProductosSubTabla;
