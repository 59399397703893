import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/productos';
import { agregarProducto } from '../../../redux/modules/carrito_compras/carrito_compras';
import DetalleProductos from './DetalleProductos';

const ms2p = (state) => {
    return {
        empresa: state.productos.detProd ? state.productos.detProd.empresa : null,
        detProd: state.productos.detProd && state.productos.detProd.producto ? state.productos.detProd.producto : {},
        fraccion: state.productos.detProd ? state.productos.detProd.fraccion : null,
        loader: state.productos.loader
    };
};

const md2p = {
    ...actions,
    agregarProducto: (producto, cantidad) => dispatch => dispatch(agregarProducto(producto, cantidad)),
};

export default connect(ms2p, md2p)(DetalleProductos);
