import React, { useEffect } from "react";
import "./listado.css";
import "rc-slider/assets/index.css";
import "../Utils/Toolbar/toolbar.css";
import { Link } from "react-router-dom";
import { Range } from "rc-slider";
import Pagination from "react-js-pagination";
import { RenderCurrencySimple } from "../Utils/renderField/renderReadField";
import Categorias from "./Categorias";

const Listado = (props) => {
    const {
        data,
        precio_inicial_productos,
        precio_final_productos,
        search,
        searchChange,
        page,
        pageChange,
        rango_inicial_productos,
        rango_final_productos,
        empresa,
        categorias,
        categoria_seleccionada,
        categorias_activas,
        url,
        isTabletDesktop,
        filtro_familia,
    } = props;

    //  Bind
    const {
        listar,
        agregarProducto,
        filtroPrecioInicialChange,
        filtroPrecioFinalChange,
        changeCategoria,
        getCategorias,
        // getFiltroFamilia,
    } = props;
    const listado = data && data.results ? data.results : [];

    const onSliderChange = (value) => {
        filtroPrecioInicialChange(value[0]);
        filtroPrecioFinalChange(value[1]);
    };

    const filtrarXPrecio = (value) => {
        listar(true);
        getCategorias(categoria_seleccionada);
    };

    // useEffect(() => {
    //     getFiltroFamilia();
    // }, []);

    return (
        <div className="row">
            {/* LISTADO DE PRODUCTOS */}
            <div className="container-tienda w-100">
                {/* BUSCADOR */}
                {/* <div className="row mb-3 mb-md-5 mb-lg-5 mt-3 mt-md-0 mt-lg-0">
                    <div className="col-12 col-md-12 col-lg-8">
                        <BuscadorTO buscar_tienda={searchChange} search_tienda={search}/>
                    </div>
                </div> */}
                <div className="row mb-5">
                    {/* FILTROS */}
                    {isTabletDesktop && (
                        <div className="col-12 col-md-3 col-lg-2 mb-3 mb-md-0 mb-lg-0 d-none d-md-block d-lg-block">
                            <div className="w-100 mb-5">
                                <div className="txt-filtro mb-3">
                                    Filtrar por precio
                                </div>
                                <div className="d-flex flex-wrap flex-column">
                                    <span className="slider-text">{`Desde Q.${precio_inicial_productos} a Q.${precio_final_productos}`}</span>
                                    {data && (
                                        <Range
                                            min={
                                                data.hasOwnProperty("min_price")
                                                    ? data.min_price > 0
                                                        ? Math.ceil(
                                                              data.min_price
                                                          ) - 1
                                                        : data.min_price
                                                    : 0
                                            }
                                            max={
                                                data.hasOwnProperty("max_price")
                                                    ? data.max_price &&
                                                      data.max_price
                                                        ? Math.ceil(
                                                              data.max_price
                                                          )
                                                        : 0
                                                    : 0
                                            }
                                            defaultValue={[
                                                precio_inicial_productos,
                                                precio_final_productos,
                                            ]}
                                            value={[
                                                precio_inicial_productos,
                                                precio_final_productos,
                                            ]}
                                            tipFormatter={(value) =>
                                                `${value}%`
                                            }
                                            allowCross={true}
                                            onChange={onSliderChange}
                                            onAfterChange={filtrarXPrecio}
                                        />
                                    )}
                                </div>
                            </div>
                            {/* <ArbolCategorias
                                url={url}
                                categorias={
                                    categorias_activas.length
                                        ? categorias_activas
                                        : categorias
                                }
                                atras={categoria_seleccionada ? true : false}
                                categoria_seleccionada={categoria_seleccionada}
                                en_producto={true}
                                changeCategoria={changeCategoria}
                            /> */}

                            <Categorias />
                        </div>
                    )}

                    {/* BUSQUEDA Y PRODUCTOS */}
                    <div className="col-12 col-md-9 col-lg-10">
                        <div className="w-100">
                            {search != "" && search != null ? (
                                <div className="titulo pl-3 mb-5">
                                    Resultados de la búsqueda &nbsp;
                                    <b>'{search}'</b>
                                </div>
                            ) : (
                                <div className="titulo pl-3 mb-5">
                                    Todos los productos
                                </div>
                            )}
                            <div className="col-12 card-producto-container">
                                {listado.map((fraccion) => {
                                    return (
                                        <div
                                            id="CARD-PRODUCTO"
                                            className="card-producto"
                                            // className={`card-producto ${fraccion.existencia <= 0 ? 'disabled' : ''}`}
                                            key={fraccion.id}
                                        >
                                            <Link
                                                className="w-100 d-flex flex-column align-items-center container-img-producto"
                                                to={`/remocar/producto/${fraccion.slug}/`}
                                            >
                                                <div className="card py-4 px-3">
                                                    {/* <img src="/static/img/logo.png" className="img-encabezado" alt=""/> */}
                                                    <img
                                                        src={
                                                            fraccion.producto &&
                                                            fraccion.producto
                                                                .img_cropped
                                                                ? fraccion
                                                                      .producto
                                                                      .img_cropped
                                                                : require("assets/img/images-empty.png")
                                                        }
                                                        // className="img-fluid"
                                                        alt={fraccion.nombre}
                                                    />
                                                </div>
                                            </Link>
                                            <div className="card-footer">
                                                <Link
                                                    className="w-100 d-flex flex-column"
                                                    to={`/remocar/producto/${fraccion.slug}/`}
                                                >
                                                    <h5 className="bold precio-producto">
                                                        <RenderCurrencySimple
                                                            value={
                                                                fraccion.precio_cliente
                                                                    ? fraccion.precio_cliente
                                                                    : 0
                                                            }
                                                        />
                                                    </h5>
                                                    <span
                                                        className="titulo-producto codigo"
                                                        title={
                                                            fraccion.producto &&
                                                            fraccion.producto
                                                                .codigo
                                                                ? fraccion
                                                                      .producto
                                                                      .codigo
                                                                : ""
                                                        }
                                                    >
                                                        {fraccion.producto &&
                                                        fraccion.producto.codigo
                                                            ? fraccion.producto
                                                                  .codigo
                                                            : ""}
                                                    </span>
                                                    <span
                                                        className="max-text-1"
                                                        title={
                                                            fraccion &&
                                                            fraccion.existencia
                                                                ? fraccion.existencia
                                                                : 0
                                                        }
                                                    >
                                                        Existencia:{" "}
                                                        {fraccion &&
                                                        fraccion.existencia
                                                            ? fraccion.existencia
                                                            : 0}
                                                    </span>
                                                    <span
                                                        className="max-text-1"
                                                        title={
                                                            fraccion.producto &&
                                                            fraccion.producto
                                                                .nombre
                                                                ? fraccion
                                                                      .producto
                                                                      .nombre
                                                                : ""
                                                        }
                                                    >
                                                        {fraccion.producto &&
                                                        fraccion.producto.nombre
                                                            ? fraccion.producto
                                                                  .nombre
                                                            : ""}
                                                    </span>

                                                    <p className="txt-sub-familia m-0 p-0">
                                                        {fraccion
                                                            ? fraccion.sub_familia
                                                            : ""}
                                                    </p>
                                                </Link>
                                                <button
                                                    className="btn-card"
                                                    onClick={() =>
                                                        agregarProducto(
                                                            fraccion
                                                        )
                                                    }
                                                    disabled={
                                                        fraccion.existencia <= 0
                                                    }
                                                >
                                                    {fraccion.existencia <=
                                                    0 ? (
                                                        <img
                                                            src={require("assets/img/icons/cart-disabled.svg")}
                                                            alt="carrito-compras"
                                                            width="45"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={require("assets/img/icons/shopping-cart-btn.svg")}
                                                            alt="carrito-compras"
                                                            width="45"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}

                                {listado.length <= 0 && (
                                    <div className="col-12 d-flex flex-column align-items-center mb-5">
                                        <span className="negro">
                                            Sin resultados
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-12">
                            {data && (
                                <Pagination
                                    pageRangeDisplayed={3}
                                    activePage={page}
                                    totalItemsCount={
                                        data.hasOwnProperty("count")
                                            ? data.count
                                            : 0
                                    }
                                    onChange={(value) => {
                                        pageChange(value);

                                        //  Scroll top
                                        window.scrollTo({
                                            top: 0,
                                            behavior: "smooth",
                                        });
                                    }}
                                    itemsCountPerPage={
                                        data.page_size ? data.page_size : 10
                                    }
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText={
                                        <img
                                            src={require(`assets/img/icons/first-page.png`)}
                                            alt=""
                                        />
                                    }
                                    prevPageText={
                                        <img
                                            src={require(`assets/img/icons/prev-page.png`)}
                                            alt=""
                                        />
                                    }
                                    nextPageText={
                                        <img
                                            src={require(`assets/img/icons/next-page.png`)}
                                            alt=""
                                        />
                                    }
                                    lastPageText={
                                        <img
                                            src={require(`assets/img/icons/last-page.png`)}
                                            alt=""
                                        />
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Listado;
