import { connect } from "react-redux";
import { actions } from "../../../redux/modules/pago/pago";
import {
    cambiarCantidad,
    quitar,
    obtenerDatosEmpresaLocal,
} from "../../../redux/modules/carrito_compras/carrito_compras";
import Pago from "./Pago";

const ms2p = (state) => {
    let total = 0;
    state.carrito_compras.productos.forEach((producto) => {
        total += producto.precio * producto.cantidad;
    });
    return {
        ...state.pago,
        total,
        empresa: state.carrito_compras.empresa,
        sucursal_tienda_online: state.usuario.me.sucursal_tienda_online,
    };
};
const md2p = {
    ...actions,
    cambiarCantidad: (cantidad, index) => (dispatch) =>
        dispatch(cambiarCantidad(cantidad, index)),
    quitar: (index) => (dispatch) => dispatch(quitar(index)),
    obtenerDatosEmpresaLocal: (slug_empresa) => (dispatch) =>
        dispatch(obtenerDatosEmpresaLocal(slug_empresa)),
};

export default connect(ms2p, md2p)(Pago);
