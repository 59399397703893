/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import _ from "lodash";
import { api } from "api";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { handleActions } from "redux-actions";
import { goBack, push } from "react-router-redux";
import { limpiarProductosCarritoLocal } from "../carrito_compras/carrito_compras";
import { initialize as initializeForm, change as changeForm } from "redux-form";

// Constantes
export const LOADER = "PAGOS_LOADER";
export const RESUMEN_PAGO = "RESUMEN";
export const DATA_PAGO = "DATA_PAGO";
export const SET_MUNICIPIO = "SET_MUNICIPIO";
export const SET_SURCURSALES_DISPONIBLES = "SET_SUCURSALES_DISPONIBLES";
export const SET_INVALIDO = "SET_INVALIDO";
export const SET_COMPRA_FINALIZADA = "SET_COMPRA_FINALIZADA";
export const SET_PRECIO_ENTREGA = "SET_PRECIO_ENTREGA";
export const SET_METODO_ENTREGA = "SET_METODO_ENTREGA";
export const SET_METODOS_ENTREGA = "SET_METODOS_ENTREGA";
export const SET_METODOS_PAGO = "SET_METODOS_PAGO";
export const SET_SLUG_EMPRESA = "SET_SLUG_EMPRESA_PAGO";

// Endpoints
const endpointTiendaEnLinea = "tienda_linea";

// Actions
export const crear = () => (dispatch, getStore) => {
    // Dispatch a LOADER.
    // dispatch(setLoader(true));
    // Obtener data del store.
    const store = getStore();
    // Obtener valores del formulario PagoForm
    const pago = store.form.PagoForm.values;
};

// Cambiar estado para ver paso dos.
export const cambioEstado = () => (dispatch, getStore) => {
    const store = getStore();
    const formData = store.form.PagoForm.values;
    if (store.form.PagoForm) {
        // Metodo de entrega
        let metodo = formData.metodo_entrega;
        metodo = metodo.split("-");
        const metodo_entrega = metodo[0];
        const metodo_entrega_precio = parseFloat(metodo[1]);
        console.log(metodo_entrega, metodo_entrega_precio);
        dispatch(setData(store.form.PagoForm.values));
        dispatch(setTotalEntrega(metodo_entrega_precio));
        dispatch(setMetodoEntrega(metodo_entrega));
    }
    const { resumenPago } = store.pago;
    dispatch(setResumen(!resumenPago));
};

export const getConfEmpresa = (slug_empresa) => (dispatch, getStore) => {
    const store = getStore();
    const formData = store.form.PagoForm.values;
    api.get(`${endpointTiendaEnLinea}/conf_empresa`, { slug_empresa })
        .catch((err) => {
            if (err) {
                Swal.fire({
                    type: "error",
                    title: "ERROR",
                    text: err.detail,
                });
            } else {
                Swal.fire({
                    type: "error",
                    title: "ERROR",
                    text: "No se ha podido obtener los datos de la empresa, intente más tarde.",
                });
            }
            dispatch(setInvalido(true));
        })
        .then((resp) => {
            if (resp.sucursales) {
                dispatch(setSucursalesDisponibles(resp.sucursales));
                if (resp.sucursales.length == 1) {
                    dispatch(
                        changeForm(
                            "PagoForm",
                            "sucursal",
                            resp.sucursales[0].id.toString()
                        )
                    );
                }
            }
            if (resp.metodos_entrega) {
                dispatch(setMetodosEntrega(resp.metodos_entrega));
                if (resp.metodos_entrega.length == 1) {
                    dispatch(
                        changeForm(
                            "PagoForm",
                            "metodo_entrega",
                            resp.metodos_entrega[0].id.toString()
                        )
                    );
                }
            }
            if (resp.metodos_pago) {
                dispatch(setMetodosPago(resp.metodos_pago));
                if (resp.metodos_pago.length == 1) {
                    dispatch(
                        changeForm(
                            "PagoForm",
                            "metodo_pago",
                            resp.metodos_pago[0].prefijo
                        )
                    );
                }
            }

            dispatch(setSlugEmpresa(slug_empresa));
        })
        .finally(() => {});
};

// Finalizar la venta.
export const finalizarVenta = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const me = store.usuario.me;
    const formData = store.form.PagoForm.values;
    let productos = formData.productos;
    console.log(productos);
    // Get total a pagar de la compra
    let total_envio = 0;
    productos &&
        productos.forEach((producto) => {
            if (producto.cantidad > 0) {
                total_envio += producto.cantidad * producto.precio_cliente;
            }
        });

    const { slug_empresa } = store.pago;

    const sucursal = me ? me.sucursal_tienda_online : null;

    const data = {
        ...formData,
        total_envio,
        slug_empresa,
        sucursal,
    };

    //  Petición POST para guardar la venta.
    api.post(endpointTiendaEnLinea, data)
        .then((resp) => {
            if (resp) {
                dispatch(setCompraFinalizada(true));
                dispatch(limpiarProductosCarritoLocal());
            }
        })
        .catch((err) => {
            Swal.fire(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

// PureActions
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setResumen = (resumenPago) => ({
    type: RESUMEN_PAGO,
    resumenPago,
});

export const setSucursalesDisponibles = (sucursalesDisponibles) => ({
    type: SET_SURCURSALES_DISPONIBLES,
    sucursalesDisponibles,
});

export const setMetodosEntrega = (metodos_entrega) => ({
    type: SET_METODOS_ENTREGA,
    metodos_entrega,
});

export const setMetodosPago = (metodos_pago) => ({
    type: SET_METODOS_PAGO,
    metodos_pago,
});

export const setTotalEntrega = (metodo_entrega_precio) => ({
    type: SET_PRECIO_ENTREGA,
    metodo_entrega_precio,
});

export const setMetodoEntrega = (metodo_entrega_label) => ({
    type: SET_METODO_ENTREGA,
    metodo_entrega_label,
});

export const setCompraFinalizada = (compraFinalizada) => ({
    type: SET_COMPRA_FINALIZADA,
    compraFinalizada,
});

export const setInvalido = (invalido) => ({
    type: SET_INVALIDO,
    invalido,
});

export const setData = (values) => ({
    type: DATA_PAGO,
    values,
});
export const setSlugEmpresa = (slug_empresa) => ({
    type: SET_SLUG_EMPRESA,
    slug_empresa,
});

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm("PagoForm", {}));
};

// Exportar actions
export const actions = {
    crear,
    cleanForm,
    cambioEstado,
    getConfEmpresa,
    finalizarVenta,
    setCompraFinalizada,
};

// Reducers
export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [RESUMEN_PAGO]: (state, { resumenPago }) => {
        return {
            ...state,
            resumenPago,
        };
    },
    [DATA_PAGO]: (state, { values }) => {
        return {
            ...state,
            values,
        };
    },
    [SET_SURCURSALES_DISPONIBLES]: (state, { sucursalesDisponibles }) => {
        return {
            ...state,
            sucursalesDisponibles,
        };
    },
    [SET_COMPRA_FINALIZADA]: (state, { compraFinalizada }) => {
        return {
            ...state,
            compraFinalizada,
        };
    },
    [SET_INVALIDO]: (state, { invalido }) => {
        return {
            ...state,
            invalido,
        };
    },
    [SET_PRECIO_ENTREGA]: (state, { metodo_entrega_precio }) => {
        return {
            ...state,
            metodo_entrega_precio,
        };
    },
    [SET_METODO_ENTREGA]: (state, { metodo_entrega_label }) => {
        return {
            ...state,
            metodo_entrega_label,
        };
    },
    [SET_METODOS_ENTREGA]: (state, { metodos_entrega }) => {
        return {
            ...state,
            metodos_entrega,
        };
    },
    [SET_METODOS_PAGO]: (state, { metodos_pago }) => {
        return {
            ...state,
            metodos_pago,
        };
    },
    [SET_SLUG_EMPRESA]: (state, { slug_empresa }) => {
        return {
            ...state,
            slug_empresa,
        };
    },
};

export const initialState = {
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    resumenPago: false,
    sucursalesDisponibles: [],
    invalido: false,
    metodos_entrega: [],
    metodos_pago: [],
    metodo_entrega_label: "",
    metodo_entrega_precio: 0,
    compraFinalizada: false,
    values: {
        nombre: "",
        apellido: "",
        departamento: "",
        municipio: "",
        direccion: "",
        telefono: "",
        correo: "",
        nit: "",
        nombreFactura: "",
        metodo_entrega: "0",
        sucursal: null,
        metodo_pago: "0",
    },
    slug_empresa: null,
};

export default handleActions(reducers, initialState);
