import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import TiendaOnlineRoute from "./TiendaOnlineRoute";

import Pago from "./common/components/Pago/index";
import EstadoCompra from "./common/components/EstadoCompra/index";
import ListadoProductos from "./common/components/ListadoProductos";
import DetalleProductos from "./common/components/DetalleProducto";

import {
    Listado as ListadoOrdenOnline,
    // Detalle as DetalleOrdenOnline,
    // Editar as EditarOrdenOnline,
} from "./common/components/OrdenOnline";

import NotFound from "./common/components/layout/NotFound/NotFound";

import "../assets/fonts/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";

require("../../node_modules/font-awesome/css/font-awesome.css");
require("../../node_modules/bootstrap/dist/css/bootstrap.css");
require("../style/index.css");

// Componente para setear el scroll hasta la parte superior de cada pagina
import ScrollToTop from "./ScrollToTop";
import { Login, Recuperar, Resetear } from "./common/components/Login";

module.exports = (
    <div>
        <ScrollToTop />
        <div className="container__content">
            <Switch>
                {/* <TiendaOnlineRoute exact path="/login" component={Login} /> */}
                <Route exact path="/" component={Login} />
                <Route path="/recuperar_contraseña" component={Recuperar} />
                <Route
                    path="/reestablecer_contraseña/:token"
                    component={Resetear}
                />
                {/*<TiendaOnlineRoute exact path="/register" component={Registro} />
                <TiendaOnlineRoute exact path="/perfil" component={Perfil} />
                <TiendaOnlineRoute exact path="/completar-perfil" component={CompletarPerfil} /> */}
                {/* <TiendaOnlineRoute
                    exact
                    path="/"
                    component={Login}
                    vista="home"
                /> */}
                <Route
                    exact
                    path="/estado_compra/:codigo"
                    component={EstadoCompra}
                />
                <TiendaOnlineRoute
                    exact
                    path="/:slug_empresa/pago"
                    component={Pago}
                    mostrar_iconos={true}
                />
                <TiendaOnlineRoute
                    exact
                    path="/:slug_empresa/pedidos"
                    component={ListadoOrdenOnline}
                    mostrar_iconos={true}
                />
                <TiendaOnlineRoute
                    exact
                    path="/:slug_empresa/producto/:slug_producto/"
                    component={DetalleProductos}
                    mostrar_iconos={true}
                />
                <TiendaOnlineRoute
                    path="/:slug_empresa/"
                    component={ListadoProductos}
                    buscador_tienda={true}
                    mostrar_iconos={true}
                    vista="tienda"
                />
                <Route component={NotFound} />
            </Switch>
        </div>
        <NotificationContainer />
    </div>
);
