import { connect } from 'react-redux';
import {push} from "react-router-redux";
import history from "../../../../utility/history";

// import { actions } from "../../../../red
// ux/modules/carrito_compras/carrito_compras";
import CarritoCompra from './CarritoCompra';


const ms2p = (state) => {

    return {
        ...state.carrito_compras
    };
};

const md2p = {
    irAlCarrito: (slug_empresa) => () => {
        history.push(`/${slug_empresa}/pago`)
    }
};

export default connect(ms2p, md2p)(CarritoCompra);
