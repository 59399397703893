import React, { Component } from 'react';

export default class BuscadorTO extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             showClear: false
        }
    }
    
    componentWillReceiveProps(nexProps) {
        if (nexProps.search_tienda != this.props.search_tienda) {
            if (nexProps.search_tienda != "" && nexProps.search_tienda != null) {
                this.setState({showClear: true});
            }else {
                this.setState({showClear: false})
            }
        }
    }

    limpiarBuscador = () => {
        this.props.buscar_tienda("")
    }

    render() {
        return (
            <div className="container-buscador-to ml-0 ml-md-3">
                <div className="w-100" style={{ position: "relative" }}>
                    <input id="buscar" type="text" name="buscar" placeholder="Buscar producto" autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                        ref={node => {
                            this.buscar_tienda = node;
                            if (this.buscar_tienda) {
                                this.buscar_tienda.value = this.props.search_tienda;
                            }
                        }}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                if (this.buscar_tienda) {
                                    this.props.buscar_tienda(this.buscar_tienda.value)
                                }
                            }
                        }}
                        onChange={() => {
                            if (this.buscar_tienda) {
                                this.props.setSearch(this.buscar_tienda.value)
                            }
                        }}
                        autoComplete="off" className="form-control buscador-to" />
                    <span className="icon-search">
                        {
                            this.state.showClear ? (
                                <img src={require("assets/img/icons/cancel.png")} alt="Limpiar" onClick={(e) => {
                                    e.preventDefault();
                                    this.limpiarBuscador();
                                }}/>
                            ): (
                                <img src={require("assets/img/icons/search-icon.svg")} alt="Buscar" />
                            )
                        }

                    </span>
                </div>
            </div>
        );
    }
}
