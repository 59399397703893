import _ from 'lodash';
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'
import EditarOrdenOnline from './EditarOrdenOnline'
// import { setNavbarCollapse } from "../../../../redux/modules/dashboard/dashboard"
import { actions } from '../../../../redux/modules/orden_online/orden_online'

const ms2p = (state) => {
    let total = 0;
    let total_envio = 0;
    const usuario = state.usuario.me;
    state.ordenes_online.seleccionados.forEach((producto) => {
        const precio = producto.precio ? parseFloat(producto.precio) : 0;
        const cantidad = producto.cantidad ? parseFloat(producto.cantidad) : 0;
        total = total + (precio * cantidad);
    });

    //  TOTAL DE ENVIO
    const { metodos_entrega, metodos_pago } = state.usuario;
    if (state.form.SeleccionMetodosForm) {
        const { values } = state.form.SeleccionMetodosForm;
        if (values) {
            const item_entrega = _.find(metodos_entrega, function(item) {return item.nombre === values.metodo_entrega});
            if (item_entrega) {
                total_envio = item_entrega.precio_empresa;
                total += total_envio
            }
        }
    }

    return {
        ...state.ordenes_online,
        usuario,
        sucursal: state.usuario.sucursal,
        metodos_pago,
        metodos_entrega,
        total,
        total_envio
    };

};

const md2p = {
    ...actions,
    // setNavbarCollapse: (estado) => dispatch => dispatch(setNavbarCollapse(estado)),
    regresar: () => dispatch => dispatch(goBack()),
}

export default connect(ms2p, md2p)(EditarOrdenOnline)
