import { connect } from "react-redux";
import Listado from "./ListadoOrdenOnline";
import { actions } from "../../../../redux/modules/pedidos_online/pedidos_online";

const ms2p = (state) => ({
    ...state.pedidos_online,
    sucursal: state.usuario.sucursal,
});

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(Listado);
