import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { RenderMoneda, RenderNumero } from "Utils/renderField/renderTableField";
import LoadMask from "Utils/LoadMask/LoadMask";
import { icons } from "icons";
import EntregaForm from './OrdenOnlineEntregaForm';
import PagoForm from './PagoForm';
import NotaOrdenOnlineForm from './NotaForm'
import AsignarSucursalForm from './AsignarSucursalForm'

class DetalleOrdenOnline extends Component {

    constructor(props) {
        super(props)

    }

    openModalAsignacion = () => {
        this.props.setModalAsignacion(true)
    };
    closeModalAsignacion = () => {
        this.props.setModalAsignacion(false)
    };

    openModalPago = () => {
        this.props.setModalPago(true);
    };

    closeModalPago = () => {
        this.props.setModalPago(false);
    }

    openModalEntrega = () => {
        this.props.setModal(true);
    }
    closeModalEntrega = () => {
        this.props.setModal(false);
    }

    componentWillMount() {
        const idOrden = this.props.match.params.id;
        this.props.cargarOrden(idOrden);
    }

    formaPago(forma_pago) {

        if (forma_pago === "EFE") {
            return ("Efectivo");
        } else if (forma_pago === "TAR") {
            return ("Tarjeta");
        } else if (forma_pago === "CHE") {
            return ("Cheque");
        } else if (forma_pago === "DEP") {
            return ("Deposito");
        } else if (forma_pago === "TRA") {
            return ("Transferencia");
        } else if (forma_pago === "TRE") {
            return ("Transferencia electronica");
        } else if (forma_pago === "PCE") {
            return ("Pago contra entrega");
        } else if (forma_pago === "PT") {
            return ("Pago en tienda");
        }
    }
    pagoSubmit = () => {
        const idOrden = this.props.match.params.id;
        this.props.corfirmarPago(idOrden);
    }

    envioSubmit = () => {
        const idOrden = this.props.match.params.id;
        this.props.confirmarEnvio(idOrden);
    }

    closeModalNota = () => {
        this.props.setModalNota(false);
    }

    guardarNota = () => {
        const idOrden = this.props.match.params.id;
        this.props.guardarNota(idOrden)
    }

    render() {

        // state
        const { data, loader, usuario, detalle, modal, modal_pago, modal_nota, data_nota, lista_sucursales, modal_asignacion, sucursal } = this.props;

        //  Bind
        const { setModalNota, cargarNota, actualizarNota, eliminarNota, asignarSucursal } = this.props;

        return (
            <div className="w-100">
                <div className="col-12">
                    <div className="d-flex flex-column flex-sm-column mb-1">
                        <div className="d-flex flex-wrap flex-md-nowrap flex-lg-nowrap grid-container " >
                            {
                                detalle.empresa && detalle.empresa.logo ? (
                                    <div className="col-lg-3 col-md-3 col-12 d-flex justify-content-center align-items-center py-1">
                                        <img  style={{ height: "85px"}} src={detalle.empresa.logo} className="d-inline-block align-top img-fluid" alt=""/>
                                    </div>
                                ): (
                                    <div  className="mr-5 col-lg-3 col-md-3 col-12 d-flex justify-content-center align-items-center py-1">
                                        <div style={{ height: "85px", width: "130px", backgroundColor: '#dcdcdc' }} className="d-flex justify-content-center align-items-center">
                                            <img src={require(`assets/img/icons/M.png`)} className="w100 d-inline-block align-top" style={{ width: '50px' }} alt="Ícono empresa" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className="separador"></div>
                            <div className="py-1 col-lg-5 col-md-5 col-12 d-block d-md-flex d-lg-flex flex-column">
                                <div className="col d-flex align-items-end">
                                    <h4 className="negro-dark2 font-weight-bold">{detalle.empresa ? detalle.empresa.nombre: "-----"}</h4>
                                </div>
                                <div className="col d-flex flex-wrap">
                                    <div className=" negro-dark2 d-flex flex-nowrap align-items-center">
                                        <img src={icons.telefono} width="15" height="15" className="d-inline-block align-top" alt="" />
                                        &nbsp;
                                        {
                                            detalle.empresa ? (
                                                <span>{detalle.empresa.telefono_tienda ? detalle.empresa.telefono_tienda : "-----"}</span>
                                            ):(
                                                <span>-----</span>
                                            )
                                        }
                                    </div>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                    <div className=" negro-dark2 d-flex flex-nowrap align-items-center">
                                        <img src={icons.correo} width="15" height="15" className="d-inline-block align-top" alt="" />
                                        &nbsp;
                                        {
                                            detalle.empresa ? (
                                                <span>{detalle.empresa.correo_tienda ? detalle.empresa.correo_tienda : "-----"}</span>
                                            ): (
                                                <span>-----</span>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* ASIGNACION SUCURSAL */}
                            <div className="py-3 col-lg-4 col-md-4 col-12 d-flex align-content-start">
                                {
                                    detalle.sucursal == null ? (
                                        <div className="w-100">
                                            <small className="text-info-orden-online">La orden no esta asignada a una sucursal, para darle seguimiento tiene que asignarla a una.</small>
                                            <br/>
                                            <button className="my-2 btn btn-primary" style={{height: "30px"}} onClick={this.openModalAsignacion}>Asignar sucursal</button>
                                        </div>
                                    ): (
                                        <div className="w-100 d-flex align-items-end justify-content-between">
                                            <div>
                                                <label className="label-view">Sucursal</label>
                                                <div className="input-view">
                                                    {detalle.sucursal.nombre}
                                                </div>
                                            </div>
                                            {
                                                (detalle.sucursal && !detalle.pagada && !detalle.enviada && detalle.notas.length <= 0 ) && (
                                                    <button className="ml-2 btn btn-primary" style={{height: "30px"}} onClick={this.openModalAsignacion}>Editar sucursal</button>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="grid-container padding-15">
                            <div className="grid-cuerpo">
                                <LoadMask loading={loader} light blur radius>
                                    <div className="grid-titulo">Datos personales</div>
                                    <div className="p-sm-0 pt-sm-1 pb-sm-1 mt">
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Nombre</label>
                                                <div className="input-view">
                                                    {detalle.nombre}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Apellido</label>
                                                <div className="input-view">
                                                    {detalle.apellido}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Dirección</label>
                                                <div className="input-view">
                                                    {detalle.direccion}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Correo</label>
                                                <div className="input-view">
                                                    {detalle.correo}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Departamento</label>
                                                <div className="input-view">
                                                    {detalle.departamento}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Municipio</label>
                                                <div className="input-view">
                                                    {detalle.municipio}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Telefono</label>
                                                <div className="input-view">
                                                    {detalle.telefono}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Nit factura</label>
                                                <div className="input-view">
                                                    {detalle.nit}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Nombre para factura</label>
                                                <div className="input-view">
                                                    {detalle.nombre_factura}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                <label className="label-view">Estado</label>
                                                <div className="input-view">
                                                    {detalle.anulado ? <span className="font-weight-bold text-uppercase text-danger">Anulado</span> : <span className="font-weight-bold text-uppercase text-success">Activo</span>}
                                                </div>
                                            </div>
                                            {
                                                detalle.sucursal && (
                                                    <div className="col-lg-3 col-md-3 col-sm-12 form-group">
                                                        <label className="label-view">Sucursal</label>
                                                        <div className="input-view">
                                                            {detalle.sucursal.nombre}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    {/* NOTAS */}
                                    <div className="grid-titulo">Notas</div>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (detalle.sucursal == null) {
                                                this.openModalAsignacion()
                                            } else {
                                                cargarNota(null)
                                            }
                                        }}
                                        className="btn btn-secondary mt-3"
                                        style={{
                                            height: "30px",
                                            marginTop: "4px"
                                        }}>
                                        <i aria-hidden="true" />Agregar nota
                                    </button>
                                    {
                                        detalle.notas && (
                                            <table className="table table-detalle-orden-online mt-3">
                                                <thead>
                                                    <tr className="m-0 font-weight-bold">
                                                        <th scope="col" className="orden-online-titulo pt-1 pb-1 negro-dark2">Nota</th>
                                                        <th scope="col" className="orden-online-titulo pt-1 pb-1 negro-dark2" style={{width: "1%"}}>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        detalle.notas.map((item, index) => {
                                                            return(
                                                                <tr key={item.id}>
                                                                    <td>
                                                                        {item.texto}
                                                                    </td>
                                                                    <td className="d-flex align-items-center justify-content-center">
                                                                        <img onClick={() => cargarNota(item)} className="action-img" title="Editar" src={icons.editar} alt="Editar"/>
                                                                        <img onClick={() => {eliminarNota(item.id)}} className="action-img" title="Eliminar" src={icons.eliminar} alt="Eliminar"/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        detalle.notas.length <= 0 && (
                                                            <tr>
                                                                <td colSpan="2" className="text-center">Sin registros</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    }

                                    <br />
                                    <div className="grid-titulo">Detalles del pedido</div>
                                    {/* PRODUCTOS */}
                                    {
                                        detalle.detalles && (
                                            <table className="table table-detalle-orden-online mt-5">
                                                <thead>
                                                    <tr className="m-0 font-weight-bold">
                                                        <th scope="col" className="orden-online-titulo pt-1 pb-1 negro-dark2">Producto</th>
                                                        <th scope="col" className="orden-online-titulo pt-1 pb-1 negro-dark2">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* PRODUCTOS */}
                                                    {
                                                        detalle.detalles.map((item, index) => {
                                                            return(
                                                                <tr key={item.id}>
                                                                    <td>
                                                                        <div style={{minHeight: "78px"}} className="orden-online-seccion-img d-flex flex-column flex-lg-row justify-content-center justify-content-lg-start align-content-center">
                                                                            <div className=" d-flex flex-column align-items-center justify-content-center">
                                                                                <img
                                                                                    style={{ height: "50px", width: "50px", marginTop: "auto", marginBottom: "auto" }}
                                                                                    src={item.fraccion.producto.imagen ? item.fraccion.producto.imagen : icons.img_default}
                                                                                    className="d-inline-block border-gris" alt="" />
                                                                            </div>
                                                                            <div className="ml-0 ml-lg-4 d-flex flex-column align-items-center justify-content-center">
                                                                                <b>{item.producto}</b>
                                                                                <small>{item.fraccion.nombre}</small>
                                                                            </div>
                                                                            <span className="d-none d-lg-block">&nbsp;&nbsp;&nbsp;</span>
                                                                            <div className="ml0 ml-lg-4 d-flex align-items-center justify-content-center">
                                                                                <RenderNumero monto={item.cantidad}></RenderNumero>
                                                                                &nbsp;
                                                                                X
                                                                                &nbsp;
                                                                                <RenderMoneda className="mr-2" monto={item.precio} />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div style={{height: "78px"}} className="d-flex align-items-center">
                                                                            <RenderMoneda className="mr-2" monto={item.precio * item.cantidad} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr className="negro-dark2">
                                                        <td className=" font-weight-bold">Sub total</td>
                                                        <td>
                                                            <RenderMoneda className="mr-2" monto={detalle.total - detalle.total_envio} />
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className=" font-weight-bold">Envío</td>
                                                        <td>
                                                            <RenderMoneda className="mr-2" monto={detalle.total_envio} />
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Forma de pago</td>
                                                        <td>
                                                            <div>{this.formaPago(detalle.forma_pago)}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Estado de pago</td>
                                                        <td>
                                                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                                                                {detalle.pagada ? (
                                                                    <Fragment>
                                                                        <div className="text-finish">Realizado</div>
                                                                        <div>
                                                                            {detalle.num_documento && (<span>&nbsp; <b>No. Doc: </b>{detalle.num_documento}</span>)}
                                                                        </div>
                                                                        {detalle.info_pago && (<div><b>Comentario: </b>{detalle.info_pago}</div>)}
                                                                    </Fragment>
                                                                ) : (
                                                                        <div className="text-pending">Pendiente</div>
                                                                    )}
                                                                {!detalle.pagada && (
                                                                    <button disabled={detalle.anulado} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (detalle.sucursal == null) {
                                                                            this.openModalAsignacion()
                                                                        }else {
                                                                            this.openModalPago()
                                                                        }
                                                                    }}

                                                                        className="btn btn-outline-secondary"
                                                                        style={{
                                                                            height: "30px",
                                                                            marginTop: "4px"
                                                                        }}><i
                                                                            aria-hidden="true" />Reportar pago</button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Metodo de envio</td>
                                                        <td>
                                                            <div>{detalle.metodo_envio}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Estado de envío</td>
                                                        <td>
                                                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                                                                {detalle.enviada ? (
                                                                    <Fragment>
                                                                        <div className="text-finish">Realizado</div>
                                                                        <div>{detalle.info_envio}</div>
                                                                    </Fragment>
                                                                ) : (
                                                                        <div className="text-pending">Pendiente</div>
                                                                    )}
                                                                {!detalle.enviada && (
                                                                    <button disabled={detalle.anulado} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (detalle.sucursal == null) {
                                                                            this.openModalAsignacion();
                                                                        }else {
                                                                            this.openModalEntrega();
                                                                        }
                                                                    }}
                                                                        className="btn btn-outline-secondary"
                                                                        style={{
                                                                            height: "30px",
                                                                            marginTop: "4px"
                                                                        }}><i
                                                                            aria-hidden="true" />Reportar envío</button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">No de guía</td>
                                                        <td>
                                                            {detalle.info_envio ? (<div>{detalle.info_envio}</div>) : (<div>Sin información</div>)}
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Dirección de envío</td>
                                                        <td>
                                                            <div>{detalle.direccion}</div>
                                                        </td>
                                                    </tr>
                                                    <tr className="negro-dark2">
                                                        <td className="font-weight-bold">Total</td>
                                                        <td>
                                                            <RenderMoneda className="mr-2" monto={detalle.total} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )
                                    }
                                    <div className="row d-flex align-items-center justify-content-center mt">
                                        <Link className="btn btn-tertiary" to={"/ordenes_online"}><i className="fa fa-arrow-left" aria-hidden="true" /> Regresar</Link>
                                    </div>
                                </LoadMask>
                                {/* MODAL DE PAGO */}
                                <Modal showCloseIcon={false} open={modal_pago} onClose={this.closeModalPago} classNames={{ modal: "modal-sm" }} >
                                    <div style={{ padding: '1.2rem', maxWidth: '400px' }}>
                                        <div className="row">
                                            <div className="col-12 grid-titulo">Reportar pago</div>
                                            <PagoForm
                                                loader={loader}
                                                onSubmit={this.pagoSubmit}
                                                close={this.closeModalPago}
                                                forma_pago={detalle ? detalle.forma_pago: ""}
                                            />
                                        </div>
                                    </div>
                                </Modal>

                                {/* MODAL DE ENVIO */}
                                <Modal showCloseIcon={false} open={modal} onClose={this.closeModalEntrega} classNames={{ modal: "modal-sm" }} >
                                    <div style={{ padding: '1.2rem', maxWidth: '400px' }}>
                                        <div className="row">
                                            <div className="col-12 grid-titulo">Reportar envío</div>
                                            <EntregaForm
                                                loader={loader}
                                                onSubmit={this.envioSubmit}
                                                close={this.closeModalEntrega}
                                            />
                                        </div>
                                    </div>
                                </Modal>

                                {/* MODAL NOTAS */}
                                <Modal showCloseIcon={false} open={modal_nota} onClose={() => setModalNota(false)} classNames={{ modal: "modal-sm" }} >
                                    <div style={{ padding: '1.2rem', maxWidth: '400px' }}>
                                        <div className="row">
                                            <div className="col-12 grid-titulo">{!data_nota ? "Nueva nota": "Editar nota" }</div>
                                            <NotaOrdenOnlineForm
                                                loader={loader}
                                                initialValues={{tipo: "10"}}
                                                onSubmit={data_nota ? actualizarNota : this.guardarNota}
                                                close={this.closeModalNota}
                                            />
                                        </div>
                                    </div>
                                </Modal>

                                {/* MODAL ASIGNAR SUCURSAL */}
                                <Modal showCloseIcon={false} open={modal_asignacion} onClose={this.closeModalAsignacion} classNames={{ modal: "modal-sm" }} >
                                    <div style={{ padding: '1.2rem', maxWidth: '400px' }}>
                                        <div className="row">
                                            <div className="col-12 grid-titulo">Asignar sucursal</div>
                                            <AsignarSucursalForm
                                                loader={loader}
                                                mostrar_info={detalle.sucursal ? false : true}
                                                sucursales={lista_sucursales}
                                                onSubmit={asignarSucursal}
                                                initialValues={{sucursal: detalle.sucursal ? detalle.sucursal.id : sucursal}}
                                                close={this.closeModalAsignacion}
                                            />
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default DetalleOrdenOnline
