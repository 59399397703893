import React, { Component } from "react";
import "./carrito-compra.css";

class CarritoCompra extends Component {
    render() {
        // State
        const { productos, empresa } = this.props;

        // Bind
        const { irAlCarrito } = this.props;

        return (
            <div
                className="align-items-baseline d-flex"
                onClick={() => irAlCarrito(empresa.slug)}
            >
                <div className="container-shopping-cart">
                    <img
                        src={require(`assets/img/icons/shopping-cart-btn.svg`)}
                        alt="Carrito de compra"
                        width="40"
                    />
                    <div className="container-quantity-items">
                        {productos.length}
                    </div>
                </div>
            </div>
        );
    }
}

export default CarritoCompra;
