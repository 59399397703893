import "./navbar.css";
import React from "react";
import { CarritoCompra } from "Utils/CarritoCompra";
import { Link } from "react-router-dom";
import BuscadorTO from "../ListadoProductos/BuscadorComponent";
import icono from "../../../../assets/img/icons/arrow-down.png";

const Navbar = (props) => {
    console.log(require("assets/img/icons/order-btn.svg"));
    console.log(icono);
    return (
        <nav className="navbar" id="NAVBAR-TO">
            <div className="align-items-start align-items-md-center align-items-lg-center container-navbar d-flex flex-column flex-md-row flex-lg-row justify-content-between w-100">
                <div className="container-brand">
                    <Link to="/" className="navbar-brand">
                        <div className="container-logo">
                            <img
                                src={require(`assets/img/remocar-logo.png`)}
                                width="120"
                                height="45"
                                className="d-inline-block align-top"
                                alt="Remocar logo"
                            />
                        </div>
                    </Link>
                    {props.mostrar_iconos && (
                        <div id="CARRITO-MOVIL">
                            <CarritoCompra />
                        </div>
                    )}
                </div>
                {props.buscador_tienda && (
                    <BuscadorTO
                        buscar_tienda={props.searchChange}
                        search_tienda={props.search}
                        setSearch={props.setSearch}
                    />
                )}

                <div className="navbar-rigth">
                    <div className="d-flex align-items-center justify-content-end">
                        {/* //? Boton Carrito compra */}
                        {props.mostrar_iconos && <CarritoCompra />}

                        {/* //? Boton Ordenes realizadas */}
                        <Link to="/remocar/pedidos" className="ml-4">
                            <img
                                src={require("assets/img/icons/order-btn.svg")}
                                width="42"
                                alt="Pedidos"
                            />
                        </Link>

                        {/* //? Boton usuario */}
                        <ul className="ml-4">
                            <li>
                                <a>
                                    {props.usuario &&
                                    props.usuario.foto_cropped ? (
                                        <img
                                            className="mr-2"
                                            width="42"
                                            src={usuario.foto_cropped}
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            width="42"
                                            className="mr-2"
                                            src={require(`assets/img/icons/perfil-btn.svg`)}
                                            alt=""
                                        />
                                    )}

                                    {props.usuario && props.usuario.first_name}
                                </a>
                                <ul>
                                    <li>
                                        <a onClick={props.logOut}>
                                            Cerrar sesion
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
