import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { renderNumberConControles } from "../../../../../../frontend/src/js/common/components/Utils/renderField/renderField";

const DetalleForm = (props) => {
    const { handleSubmit, detProd, fraccion} = props;
    const [cantidad, setCantidad] = useState(0); // Inicializar el estado para almacenar el valor de cantidad
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const existencia = fraccion.existencia;

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <br />
                <div className="control-cantidad">
                    <Field
                        name="cantidad"
                        component={renderNumberConControles}
                        type="text"
                        className="form-control"
                        minValue={0}
                        onValueChange={(values) => {
                            let newValue = parseFloat(values.value) || 0;
                            setCantidad(newValue);
                            if (newValue > existencia) {
                                setIsButtonDisabled(true);
                            } else {
                                setIsButtonDisabled(false);
                            }
                        }}
                        cssIcon
                    />
                </div>
                <button className={`btn-carrito-detalle ${existencia <= 0 ? 'disabled' : ''}`} type="submit" disabled={isButtonDisabled}>
                    Añadir al carrito
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "detProduct",
})(DetalleForm);
