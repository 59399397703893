import _ from "lodash";
import Swal from 'sweetalert2';
import { handleActions } from "redux-actions";
import { registrarProductos, obtenerProductos, obtenerEmpresa, limpiarProductos } from './localStorageCarrito'
import history from "../../../utility/history";
import { asignarEmpresa } from "../productos"

// ------------------------------------
// Constants
// ------------------------------------
export const PRODUCTO = "CARRITO_COMPRAS_PRODUCTO";
export const SET_EMPRESA = "CARRITO_COMPRAS_SET_EMPRESA";

// ------------------------------------
// Actions
// ------------------------------------
export const agregarProducto = (productoSeleccionado, cantidad=undefined) => (dispatch, getStore) => {
    const store = getStore();
    let productos = _.cloneDeep(store.carrito_compras.productos);
    let producto = _.cloneDeep(productoSeleccionado);
    // if(producto.existe_descontable)

    const items = _.filter(productos, { id: producto.id });
    producto.cantidad = cantidad ? cantidad : 1;
    //  SE CALCULA EL INVENTARIO DEL MISMO PRODUCTO SI SE INGRESO VARIOS
    let cantidad_total = producto.cantidad;

    //  SE SUMA TODAS LAS CANTIDADES DE LOS PRODUCTOS ENCONTRADOS
    items.forEach(item => {
        cantidad_total += item.cantidad;
    })

    producto.num_precios = 0;
    producto.listado_precios = [];
    //  Se valida que si tiene varios precios para dar la opcion de seleccionar
    if (producto.precio) {
        producto.num_precios += 1;
        producto.modelo_precio = {id:1, value:producto.precio};
        producto.listado_precios.push({id:1, value:producto.precio.toFixed(2)});
    }
    producto.precio = producto.precio_online;
    producto.es_vale = false;
    producto.precio_original = producto.precio;
    if(items.length) {
        const indexSeleccionado = _.indexOf(productos, items[0]);
        producto.cantidad = cantidad_total;
        producto.precio_original = items[0].precio_original;
        productos.splice(indexSeleccionado, 1, producto)
        dispatch(setProduct(productos))
    }else {
        dispatch(setProduct([...productos, producto]));
    }

    //  Se registra en el localStorage
    dispatch(registrarProductoLocalStorage());

    //  Swal para notificar al cliente que agrego un producto al carrito
    Swal.fire({
        type: 'success',
        title: 'Éxito',
        width: 550,
        html: `Has agregado <b>${productoSeleccionado.producto.nombre}</b> al carrito de compras`,
        showCancelButton: true,
        confirmButtonColor: '#2A9FFF',
        cancelButtonColor: '#4D4D4D',
        confirmButtonText: 'Ir al carrito de compras',
        cancelButtonText: 'Continuar comprando'
    }).then((result) => {
        const slug_empresa = store.carrito_compras.empresa.slug;
        if (result.value) {
            history.push(`/${slug_empresa}/pago`);
        }else if (result.dismiss == "cancel") {
            if (cantidad) {
                history.push(`/${slug_empresa}`);
            }
        }

    })

    // // } else {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'ERROR',
    //         text: "No cuenta con inventario para este producto"
    //     });
    // }
}

const registrarProductoLocalStorage = () => (dispatch, getStore) => {
    const store = getStore()
    const { empresa } = store.carrito_compras;
    registrarProductos(store.carrito_compras.productos, empresa.slug);
}

export const cambiarCantidad = (cantidad, index) => (dispatch, getStore) => {
    const store = getStore();
    const productos = _.cloneDeep(store.carrito_compras.productos);
    const item = productos[index];
    if (item) {
        productos[index].cantidad = cantidad
        dispatch(setProduct(productos));
        //  Se registra en el localStorage
        dispatch(registrarProductoLocalStorage());
    }
}

export const quitar = (index) => (dispatch, getStore) => {
    const store = getStore();
    let productos = _.cloneDeep(store.carrito_compras.productos);
    const item = productos[index];
    if (item) {
        productos.splice(index, 1);
        dispatch(setProduct(productos));
        dispatch(registrarProductoLocalStorage(productos));

        // Se redirecciona a la tienda si el carrito queda vacio
        if (!productos.length) {
            const slug_empresa = store.carrito_compras.empresa.slug;
            history.push(`/${slug_empresa}`);
        }
    }
}

export const obtenerDatosEmpresaLocal = (slug_empresa) => (dispatch) => {
    const datosEmpresa = obtenerEmpresa(slug_empresa);
    dispatch(setEmpresa(datosEmpresa));
    dispatch(setProduct(obtenerProductos(slug_empresa)));
    if (datosEmpresa == null) {
        dispatch(asignarEmpresa(slug_empresa))
    }
}

export const limpiarProductosCarritoLocal = () => (dispatch, getStore) => {
    const store = getStore()
    const { empresa } = store.carrito_compras;
    limpiarProductos(empresa.slug)
    dispatch(setProduct(obtenerProductos(empresa.slug)));
}

// ------------------------------------
// PureActions
// ------------------------------------
export const setProduct = productos => ({
    type: PRODUCTO,
    productos
})

export const setEmpresa = empresa => ({
    type: SET_EMPRESA,
    empresa
})

export const actions = {
    quitar,
    agregarProducto,
    cambiarCantidad,
    obtenerDatosEmpresaLocal,
}
// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [PRODUCTO]: (state, { productos }) => {
        return {
            ...state,
            productos,
        };
    },
    [SET_EMPRESA]: (state, { empresa }) => {
        return {
            ...state,
            empresa,
        };
    },
}

// ------------------------------------
// InitialState
// ------------------------------------
// const localProductos = obtenerProductos();
// const localEmpresa = obtenerEmpresa();
export const initialState = {
    productos: [],
    empresa: null,
}

export default handleActions(reducers, initialState);
