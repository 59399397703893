import PropTypes from "prop-types";
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { validations } from "validations";
import logo_remocar from "../../../../../assets/img/remocar-logo.png";
// import BackgroundLogin from "../../../../../assets/img/background-login.jpg";
import "../login.css";

class Login extends Component {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            username: "",
            password: "",
            campoEmailTouched: false,
            campoPasswordTouched: false,
        };
    }

    campoUsuarioMarkAsTouched = () => {
        this.setState({ campoEmailTouched: true });
    };

    campoPasswordMarkAsTouched = () => {};

    render() {
        const { passError, nameError, submitError, usuario } = this.props;
        // bind
        const { getMe } = this.props;

        // console.log(usuario);

        if (localStorage.getItem("token")) {
            return <Redirect to="/remocar" />;
        }
        return (
            <div className="fondo-login">
                <div className="login-wrapper">
                    <div className="d-none d-md-block d-lg-none container-img-fondo">
                        <img className="img-login" src={logo_remocar} />
                    </div>
                    <div className="contenedor-form-login">
                        <div>
                            <img className="img-login" src={logo_remocar} />
                        </div>
                        <h4 className="font-weight-bolder mb-4">
                            Tienda en línea
                        </h4>
                        <div className="container-controls">
                            <div id="login" className="panel-body">
                                {/* <p className="text-center pv">INGRESE SU USUARIO Y CONTRASEÑA.</p> */}
                                <form
                                    name="loginForm"
                                    className="form-validate mb-lg"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        this.props.onSubmit(this.state);
                                    }}
                                >
                                    <div className="form-group has-feedback">
                                        <span className="fa fa-user form-control-feedback text-muted text-muted-login" />
                                        <input
                                            autocomplete="off"
                                            id="inputUsername"
                                            type="text"
                                            name="account_username"
                                            placeholder="Email"
                                            ref={(node) => {
                                                this.userEmail = node;
                                            }}
                                            onChange={() => {
                                                this.setState({
                                                    campoEmailTouched: true,
                                                });
                                                if (this.userEmail) {
                                                    this.setState({
                                                        email: this.userEmail
                                                            .value,
                                                    });
                                                }
                                            }}
                                            autoComplete="off"
                                            required=""
                                            className="form-control form-control-login"
                                            autoFocus="true"
                                        />
                                        {this.state.campoEmailTouched ? (
                                            <p className="form-notice">
                                                {validations.required(
                                                    this.state.email
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="form-group has-feedback">
                                        <span className="fa fa-lock form-control-feedback text-muted text-muted-login" />
                                        <input
                                            autocomplete="off"
                                            id="inputPassword"
                                            type="password"
                                            name="account_password"
                                            placeholder="Contraseña"
                                            ref={(node) => {
                                                this.userPassword = node;
                                            }}
                                            onChange={() => {
                                                this.setState({
                                                    campoPasswordTouched: true,
                                                });
                                                if (this.userPassword) {
                                                    this.setState({
                                                        password:
                                                            this.userPassword
                                                                .value,
                                                    });
                                                }
                                            }}
                                            required=""
                                            className="form-control form-control-login"
                                        />
                                        {this.state.campoPasswordTouched ? (
                                            <p className="form-notice">
                                                {validations.required(
                                                    this.state.password
                                                )}
                                            </p>
                                        ) : null}
                                    </div>
                                    <div className="clearfix" />
                                    {this.props.loader && (
                                        <div className="loader-container">
                                            <div className="loader" />
                                        </div>
                                    )}
                                    {!this.props.loader && (
                                        <div className="footer-login">
                                            <button
                                                onClick={(e) => {
                                                    this.setState({
                                                        campoUsuarioTouched: true,
                                                    });
                                                    this.setState({
                                                        campoPasswordTouched: true,
                                                    });
                                                    e.preventDefault();
                                                    this.props.onSubmit(
                                                        this.state
                                                    );
                                                }}
                                                className="w-100 btn btn-block btn-primary mt-lg"
                                                type={"submit"}
                                            >
                                                Inciar sesión
                                            </button>
                                            <br />
                                            <small className="font-weight-bold">
                                                ¿Olvidaste tu contraseña?{" "}
                                                <Link to="/recuperar_contraseña">
                                                    Recuperar
                                                </Link>
                                            </small>
                                            <br />
                                        </div>
                                    )}
                                </form>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
