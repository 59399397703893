import React, { Component } from "react";

import _ from "lodash";
import { icons } from "icons";
import { tableOptions } from "Utils/tableOptions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TablaPendiente } from "./TablaPendiente";

import "./customTab.css";
class ListadoOrdenOnline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalListar: null,
            tab: 10,
        };
    }

    componentDidMount() {
        this.props.listar(this.props.page);
        //  Interval para actualizar el listado de ordenes a cada 30 segundos
        // let intervalListar = setInterval(this.listarOrdenes.bind(this), 30000);
        // this.setState({ intervalListar });
    }
    componentWillUnmount() {
        //  Se limpia el interval para que ya no siga actualizando
        clearInterval(this.state.intervalListar);
    }

    listarOrdenes() {
        this.props.listar(this.props.page);
    }

    imageFormatter(cell, row) {
        if (cell === true)
            return <img src={icons.check} style={{ width: "20px" }} />;
        else cell === false;
        return <img src={icons.reloj} style={{ width: "20px" }} />;
    }

    formaPago(cell, row) {
        let forma_pago = row.forma_pago;

        if (forma_pago === "EFE") {
            return <div>Efectivo</div>;
        } else if (forma_pago === "TAR") {
            return <div>Tarjeta</div>;
        } else if (forma_pago === "CHE") {
            return <div>Cheque</div>;
        } else if (forma_pago === "DEP") {
            return <div>Deposito</div>;
        } else if (forma_pago === "TRA") {
            return <div>Transferencia</div>;
        } else if (forma_pago === "TRE") {
            return <div>Transferencia electronica</div>;
        } else if (forma_pago === "PCE") {
            return <div>Pago contra entrega</div>;
        } else if (forma_pago === "PT") {
            return <div>Pago en tienda</div>;
        } else {
            return <div>{forma_pago}</div>;
        }
    }

    render() {
        // state
        const { data, loader, search, buscar, estado } = this.props;
        // Bind
        const { anular, estadoChange, changeTab } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div className="row px-0 px-md-5">
                <div className="col-12">
                    <div className="grid-container">
                        <Tabs
                            className="mt-2 content-tabs"
                            selectedIndex={this.state.tab == 10 ? 0 : 1}
                            onSelect={(tabIndex) => {
                                this.setState({ tab: tabIndex == 0 ? 10 : 20 });
                                changeTab(tabIndex == 0 ? 10 : 20);
                            }}
                        >
                            <TabList className="tab_list d-flex flex-row justify-content-between">
                                <Tab
                                    className="content-tab-venta flex1 text-center fnt-14"
                                    style={{ marginBottom: "0px" }}
                                    selectedClassName="content-tab-venta-active"
                                >
                                    Pendientes
                                </Tab>
                                <Tab
                                    className="content-tab-venta flex1 text-center fnt-14"
                                    style={{ marginBottom: "0px" }}
                                    selectedClassName="content-tab-venta-active"
                                >
                                    Historial
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <TablaPendiente
                                    buscar={buscar}
                                    search={search}
                                    loader={loader}
                                    data={data}
                                    options={options}
                                />
                            </TabPanel>

                            <TabPanel>
                                <TablaPendiente
                                    buscar={buscar}
                                    search={search}
                                    loader={loader}
                                    data={data}
                                    options={options}
                                    esHistorial
                                />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListadoOrdenOnline;
