import _ from "lodash";
// import { icons } from "icons";
import React from "react";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
// import { activeFormatter } from "Utils/Acciones/Acciones";
import { BreakLine } from "Utils/tableOptions";
import { RenderDate } from "Utils/renderField/renderReadField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import { ExpandColumn, ProductosSubTabla } from "../SubTablaComponents";

export const TablaPendiente = ({
    buscar,
    search,
    loader,
    data,
    options,
    esHistorial = false,
}) => {
    const isExpandableRow = () => true;
    console.log(data)
    return (
        <div>
            <div className="grid-titulo">
                <Toolbar
                    titulo={"Ordenes online"}
                    buscar={buscar}
                    search={search}
                />
            </div>
            <LoadMask loading={loader} dark blur>
                <BootstrapTable
                    data={loader ? [] : data.results}
                    remote
                    pagination
                    hover
                    fetchInfo={{ dataTotalSize: data.count }}
                    options={options}
                    expandableRow={isExpandableRow}
                    expandComponent={ProductosSubTabla}
                    expandColumnOptions={{
                        expandColumnVisible: true,
                        expandColumnComponent: ExpandColumn,
                        columnWidth: 50,
                    }}
                >
                    <TableHeaderColumn
                        dataField="codigo_orden"
                    >
                        Codigo de Orden
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="creado"
                        dataFormat={(cell) => <RenderDate value={cell} />}
                        dataSort
                    >
                        Fecha del pedido
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="total"
                        dataFormat={(cell, row) => {
                            const to_decimal = cell.toFixed(2);
                            return `Q ${to_decimal}`;
                        }}
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Total
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="transporte">
                        Transporte
                    </TableHeaderColumn>

                    <TableHeaderColumn dataField="guia">Guía</TableHeaderColumn>

                    <TableHeaderColumn dataField="estado_pedido">
                        Estado
                    </TableHeaderColumn>

                    {esHistorial && (
                        <TableHeaderColumn dataField="recibido_por">
                            Recibido por
                        </TableHeaderColumn>
                    )}
                    {esHistorial && (
                        <TableHeaderColumn
                            dataField="fecha_entrega"
                            dataFormat={(cell) => <RenderDate value={cell} />}
                            dataSort
                        >
                            Fecha de entrega
                        </TableHeaderColumn>
                    )}
                    <TableHeaderColumn
                        dataField="direccion"
                        dataFormat={cell => cell.direccion}
                    >
                        Direccion de entrega
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="metodo_envio"
                        dataFormat={(cell) => {
                            if (cell === "retirar_tienda")
                                return "Retirar en tienda";
                            if (cell === "enviar_direccion")
                                return "Enviar a domicio";
                            return "---";
                        }}
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                    >
                        Metodo de envio
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="id"
                        isKey
                        // dataFormat={this.imageFormatter}
                        dataAlign="center"
                        width="0"
                    >
                        ID
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                        dataField="id"
                        isKey
                        dataAlign="center"
                        width={"120px"}
                        dataFormat={(cell, row) => {
                            return activeFormatter({
                                ver: "/ordenes_online/detalle",
                            })(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn> */}
                </BootstrapTable>
            </LoadMask>
        </div>
    );
};
