import { api } from "api";

const useAsyncSearch = (endpoint, listado_options, initial_params = {}) => {
    const getOptions = (search) => {
        let options = {}
        endpoint === 'sub-familia' ?
            options = { search, async_options: true, raw: true, ...initial_params } :
            options = { search, async_options: true, ...initial_params }
        
        return api
            .get(endpoint, options)
            .catch(() => {})
            .then((data) => {
                data.results.forEach((item) => {
                    if (!_.find(listado_options, { id: item.id }))
                        listado_options.push(item);
                });
                return { options: listado_options };
            });
    };

    return { getOptions };
};

export default useAsyncSearch;
