import React, { useEffect, useState } from "react";
import { Async } from "react-select";
import useAsyncSearch from "../../../hooks/useAsyncSearch";

const listado_familias = [];
let listado_subfamilias = [];

const Categorias = ({
    filtro_familia,
    filtro_sub_familia,
    setFiltroFamilia,
    setFiltroSubFamilia,
}) => {
    const { getOptions: getFamilias } = useAsyncSearch(
        "familia",
        listado_familias
    );
    const { getOptions: getSubfamilias } = useAsyncSearch(
        "sub-familia",
        listado_subfamilias,
        {
            familia_id: filtro_familia ? filtro_familia.id : "",
        }
    );

    return (
        <React.Fragment>
            <div className="d-flex flex-column">
                <label className="mb-4 tree-title">Familias</label>
                <div className="flex-3 mb-3">
                    <label>Familia</label>
                    <Async
                        className="form-control"
                        labelKey="nombre"
                        valueKey="id"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getFamilias}
                        placeholder={"Familia"}
                        onChange={(e) => {
                            // cambiar el estado de la familia
                            setFiltroFamilia(e);

                            // cambiar el estado de la subfamilia
                            listado_subfamilias = [];
                            setFiltroSubFamilia(null);
                        }}
                        value={filtro_familia}
                    />
                </div>

                <div className="flex-3">
                    <label>Sub Familia</label>
                    <Async
                        key={`sub-${filtro_familia ? filtro_familia.id : ""}`}
                        className="form-control"
                        labelKey="nombre"
                        valueKey="id"
                        searchPromptText="Escriba para buscar"
                        loadOptions={getSubfamilias}
                        placeholder={"Sub Familia"}
                        onChange={(e) => {
                            setFiltroSubFamilia(e);
                        }}
                        disabled={!filtro_familia}
                        value={filtro_sub_familia}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default Categorias;
