import React, { useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import {
    renderFieldRadio,
    renderSearchSelect,
} from "../Utils/renderField/renderField";
import _ from "lodash";
import { Link } from "react-router-dom";
import useAsyncSearch from "../../hooks/useAsyncSearch";

// Verifica si se muestra la información del banco o no.
// let mostrarBanco = false;

const validate = (values, props) => {
    const errors = {};

    if (!values.metodo_entrega) {
        errors.metodo_entrega = "Campo requerido";
    }

    if (values.metodo_entrega === "enviar_direccion") {
        if (!values.direccion) {
            errors.direccion = "Campo requerido";
        }

        if (!values.transporte) {
            errors.transporte = "Campo requerido";
        }
    }

    return errors;
};

const listado_direccion = [];
const listado_transporte = [];

let PasoDosForm = ({
    handleSubmit,
    previousStep,
    sucursal_tienda_online,
    empresa,
    metodo_entrega,
}) => {
    const { getOptions: getDireccion } = useAsyncSearch(
        "clientes_direcciones",
        listado_direccion,
        { id_cliente: 1 }
    );

    const { getOptions: getTransporte } = useAsyncSearch(
        "transportes",
        listado_transporte
    );

    useEffect(() => {
        const radio_true = document.querySelector("#retirar_tienda");
        radio_true.click();
    }, []);

    return (
        <div className="row">
            <div className="form-container mb-5">
                <form className="container-form" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="w-100 form-group">
                            {/* DATOS PERSONALES */}
                            <div className="grid-titulo d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between mb-3 pb-1">
                                <span>Método de entrega</span>
                                {empresa && (
                                    <Link
                                        to={`/${empresa.slug}`}
                                        className="btn-volver-carrito mb-2 d-none d-md-flex d-lg-flex"
                                    >
                                        Continuar comprando
                                    </Link>
                                )}
                            </div>

                            <div className="d-flex flex-column">
                                <div className="d-flex form-group">
                                    <div className="d-flex flex-column">
                                        <Field
                                            type="radio"
                                            id="retirar_tienda"
                                            name="metodo_entrega"
                                            component={renderFieldRadio}
                                            // checked="checked"
                                            value="retirar_tienda"
                                            label="Retirar en tienda"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="pl-4 mb-3">
                                    <label>
                                        {sucursal_tienda_online.nombre}
                                    </label>
                                    <div className="d-flex flex-column flex-md-row pr-0">
                                        <div className="w-100 d-flex flex-column fnt-18 text-primary">
                                            {sucursal_tienda_online.direccion}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex form-group">
                                    <div className="d-flex flex-column">
                                        <Field
                                            type="radio"
                                            name="metodo_entrega"
                                            component={renderFieldRadio}
                                            label="Entregar a domicilio"
                                            valueKey="label"
                                            value="enviar_direccion"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="d-flex flex-column">
                                    <div className="col-12 col-md-6 col-lg-6 form-group">
                                        <label htmlFor="direccion">
                                            1. Selecciona la dirección de
                                            entrega
                                        </label>
                                        <div className="d-flex flex-column flex-md-row pr-0">
                                            <div className="w-100 d-flex flex-column">
                                                <Field
                                                    name="direccion"
                                                    component={
                                                        renderSearchSelect
                                                    }
                                                    loadOptions={getDireccion}
                                                    labelKey="direccion"
                                                    valueKey="id"
                                                    className="form-control"
                                                    disabled={
                                                        metodo_entrega ===
                                                        "retirar_tienda"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 form-group">
                                        <label htmlFor="transporte">
                                            2. Selecciona el proveedor de
                                            entrega
                                        </label>
                                        <div className="d-flex flex-column flex-md-row pr-0">
                                            <div className="w-100 d-flex flex-column">
                                                <Field
                                                    name="transporte"
                                                    component={
                                                        renderSearchSelect
                                                    }
                                                    loadOptions={getTransporte}
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    className="form-control"
                                                    disabled={
                                                        metodo_entrega ===
                                                        "retirar_tienda"
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* FINAL METODO DE PAGO */}
                            <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-center btn-form mt-5">
                                <button
                                    className="btn-volver-carrito mx-3 my-2 my-lg-0"
                                    onClick={previousStep}
                                >
                                    Anterior
                                </button>
                                <button
                                    type="submit"
                                    className="btn-comprar-carrito btn-pago btn-lg mx-3 my-2 my-lg-0"
                                >
                                    Continuar con la orden
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

PasoDosForm = reduxForm({
    form: "PagoForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
})(PasoDosForm);

const selector = formValueSelector("PagoForm");
PasoDosForm = connect((state) => {
    const metodo_entrega = selector(state, "metodo_entrega");
    const storeCarrito = state.carrito_compras;
    const empresa = storeCarrito ? storeCarrito.empresa : null;

    return {
        empresa,
        metodo_entrega,
    };
})(PasoDosForm);

export default PasoDosForm;
