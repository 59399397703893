import React, { Component } from 'react'
import "./arbol_categorias.css"
import { Link } from "react-router-dom";

const ComponentChild = (props) => {
    //  State
    const { categoria, categoria_seleccionada, en_producto, url } = props;
    //  Bind
    const { changeCategoria } = props;

    if (categoria.childs.length > 0) {
        return (
            <ol key={`childs-${categoria.id}`}>
                {categoria.childs.map(child => {
                    if (child.childs.length > 0) {
                        return (
                            <li className="parent" key={`childs-${child.id}`}>
                                <input type="checkbox" id={`${categoria.id}-${child.id}`} />
                                <label htmlFor={`${categoria.id}-${child.id}`}></label>
                                {
                                    en_producto ? (
                                        <div className={`titulo ${categoria_seleccionada == child.id ? 'active' : ''}`}>
                                            <Link to={`${url}${child.url_slug}`} replace={true} className={`a-remove-fromat`} onClick={() => {
                                                changeCategoria(child.slug)
                                            }}>
                                                {child.nombre} <span className="ml-1 cantidad">({en_producto ? child.total_productos : child.total_empresas})</span>
                                            </Link>
                                        </div>
                                    ): (
                                        <div className={`titulo ${categoria_seleccionada == child.id ? 'active' : ''}`} onClick={() => changeCategoria(child.id)}>
                                            {child.nombre} <span className="ml-1 cantidad">({en_producto ? child.total_productos : child.total_empresas})</span>
                                        </div>
                                    )
                                }
                                <ComponentChild categoria={child} categoria_seleccionada={categoria_seleccionada} en_producto={en_producto} changeCategoria={changeCategoria}  url={url}/>
                            </li>
                        )
                    } else {
                        return (
                            <li className="leaf" key={`childs-${child.id}`}>
                                {
                                    en_producto ? (
                                        <div className={`titulo ${categoria_seleccionada == child.id ? 'active' : ''}`}>
                                            <Link to={`${url}${child.url_slug}`} replace={true} className={`a-remove-fromat`} onClick={() => {
                                                changeCategoria(child.slug)
                                            }}>
                                                {child.nombre} <span className="ml-1 cantidad">({en_producto ? child.total_productos : child.total_empresas})</span>
                                            </Link>
                                        </div>
                                    ): (
                                        <div className={`titulo ${categoria_seleccionada == child.id ? 'active' : ''}`} onClick={() => changeCategoria(child.id)}>
                                            {child.nombre} <span className="ml-1 cantidad">({en_producto ? child.total_productos : child.total_empresas})</span>
                                        </div>
                                    )
                                }
                            </li>
                        )
                    }
                })}
            </ol>
        )
    } else {
        <span>no data</span>
    }
}

export default class ArbolCategorias extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expanded: [],
            checked: []
        }
    }

    render() {
        //  State
        const { categorias, categoria_seleccionada, en_producto, titulo, atras, url, movil_empresas } = this.props;
        //  Bind
        const { changeCategoria } = this.props
        return (
            <div className="w-100 mt-4">
                <label className="mb-3 tree-title">Familias</label>
                {categorias.length > 0 && (
                    <ol className="tree">
                        {/* SECCION DE LA OPCION TODOS O PARA REGRESAR */}
                        {atras && categorias[0].parent ? (
                            en_producto ? (
                                categorias[0].ancestors.map(ancestor => {
                                    return (
                                        <li className="leaf ancestors" key={ancestor.slug}>
                                            <Link to={`${url}${ancestor.url_slug}`} replace={true} className={`titulo a-remove-fromat`} onClick={() => {
                                                changeCategoria(ancestor.slug)
                                            }}> <em className="fa fa-angle-left mr-1"></em> {ancestor.nombre}</Link>
                                        </li>
                                    )
                                })
                            ): (
                                movil_empresas ? (
                                    categorias[0].ancestors.map(ancestor => {
                                        return (
                                            <li className="leaf ancestors" key={ancestor.slug}>
                                                <div className={`titulo`} onClick={() => changeCategoria(ancestor.id)}>
                                                    <em className="fa fa-angle-left mr-1"></em> {ancestor.nombre}
                                                </div>
                                            </li>
                                        )
                                    })
                                ): (
                                    <li className="leaf ancestors">
                                        <div className={`titulo ${categoria_seleccionada == null ? 'active' : ''}`} onClick={() => changeCategoria(categorias[0].parent)}>Regresar</div>
                                    </li>
                                )
                            )
                        ): (
                            <li className="leaf">
                                {
                                    en_producto ? (
                                        <Link to={`${url}`} replace={true} className={`titulo a-remove-fromat ${categoria_seleccionada == null ? 'active' : ''}`} onClick={() => {
                                            changeCategoria(null)
                                        }}>Todos</Link>
                                    ):(
                                        <div className={`titulo ${categoria_seleccionada == null ? 'active' : ''}`} onClick={() => changeCategoria(null)}>Todos</div>
                                    )
                                }
                            </li>
                        )}

                        {/* SECCION DE CATEGORIAS */}
                        {
                            categorias.map(categoria => {
                                if (categoria.childs.length) {
                                    return (
                                        <li className="parent" key={`childs-${categoria.id}`}>
                                            {categorias.length == 1 ? (
                                                <input type="checkbox" id={categoria.id} checked={true} readOnly/>
                                            ): (
                                                <input type="checkbox" id={categoria.id} />
                                            )}
                                            <label htmlFor={categoria.id}></label>
                                            {
                                                en_producto ? (
                                                    <div className={`titulo ${categoria_seleccionada == categoria.id || categoria_seleccionada == categoria.slug ? 'active' : ''}`}>
                                                        <Link to={`${url}${categoria.url_slug}`} replace={true} className={`a-remove-fromat`} onClick={() => {
                                                            changeCategoria(categoria.slug)
                                                        }}>
                                                            {categoria.nombre} <span className="ml-1 cantidad">({en_producto ? categoria.total_productos : categoria.total_empresas})</span>
                                                        </Link>
                                                    </div>
                                                ): (
                                                    <div className={`titulo ${categoria_seleccionada == categoria.id || categoria_seleccionada == categoria.slug ? 'active' : ''}`} onClick={() => changeCategoria(categoria.id)}>
                                                        {categoria.nombre} <span className="ml-1 cantidad">({en_producto ? categoria.total_productos : categoria.total_empresas})</span>
                                                    </div>
                                                )
                                            }
                                            <ComponentChild categoria={categoria} categoria_seleccionada={categoria_seleccionada} en_producto={en_producto} changeCategoria={changeCategoria} url={url}/>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className="leaf" key={`childs-${categoria.id}`}>
                                            {
                                                en_producto ? (
                                                    <div className={`titulo ${categoria_seleccionada == categoria.id || categoria_seleccionada == categoria.slug ? 'active' : ''}`}>
                                                        <Link to={`${url}${categoria.url_slug}`} replace={true} className={`a-remove-fromat`} onClick={() => {
                                                            changeCategoria(categoria.slug)
                                                        }}>
                                                            {categoria.nombre} <span className="ml-1 cantidad">({en_producto ? categoria.total_productos : categoria.total_empresas})</span>
                                                        </Link>
                                                    </div>
                                                ):(
                                                    <div className={`titulo ${categoria_seleccionada == categoria.id || categoria_seleccionada == categoria.slug ? 'active' : ''}`} onClick={() => changeCategoria(categoria.id)}>
                                                        {categoria.nombre} <span className="ml-1 cantidad">({en_producto ? categoria.total_productos : categoria.total_empresas})</span>
                                                    </div>
                                                )
                                            }
                                        </li>
                                    )
                                }
                            })
                        }

                    </ol>
                )}
            </div>
        )
    }
}

