import React, { Component, Fragment } from "react";
import Paso01Form from "./Paso01Form";
import Paso02Form from "./Paso02Form";
import Paso03Form from "./Paso03Form";
import { Link } from "react-router-dom";
import LoadMask from "Utils/LoadMaskTO";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import "./pago.css";

class Pago extends Component {
    state = {
        step: 1,
        municipios: [],
    };

    componentWillMount() {
        const { slug_empresa } = this.props.match.params;
        this.props.obtenerDatosEmpresaLocal(slug_empresa);
        this.props.cleanForm();
        this.props.getConfEmpresa(slug_empresa);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.empresa !== nextProps.empresa) {
            //  Asignacion de titulo de la pagina
            document.title = nextProps.empresa
                ? `${nextProps.empresa.nombre} | Carrito de compras`
                : "Remocar.gt";
        }
    }

    // Manejo de cambio de pasos en StepProgressBar
    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    };
    previousStep = () => {
        this.setState({ step: this.state.step - 1 });
    };

    municipiosChange = (municipios) => {
        this.setState({ municipios });
    };

    render() {
        const {
            loader,
            finalizarVenta,
            total,
            compraFinalizada,
            empresa,
            sucursal_tienda_online,
        } = this.props;
        const { step } = this.state;
        // Bind Carrito de compras
        const { cambiarCantidad, quitar } = this.props;
        // Bind pago
        const { setCompraFinalizada } = this.props;

        return (
            <div style={{ backgroundColor: "#F5F5F5" }}>
                {compraFinalizada ? (
                    <div className="compraFinalizada">
                        <div>
                            <img
                                src={require(`assets/img/icons/exito.png`)}
                                className="d-inline-block align-top img-exito"
                                alt=""
                            ></img>
                        </div>
                        <br></br>
                        <div>
                            <h3 className="titulo-exito">
                                ¡Muchas gracias por tu compra!
                            </h3>
                            <br></br>
                            <span className="txt-exito">
                                Tu orden se realizó de forma exitosa, puedes
                                verificar el estado de tu pedido en la sección
                                de "Mis pedidos".
                            </span>
                        </div>
                        {empresa && (
                            <Link
                                to={`/${empresa.slug}`}
                                className="btn-comprar-carrito btn-lg btn-pago my-4"
                                onClick={() => setCompraFinalizada(false)}
                            >
                                Ir a la tienda
                            </Link>
                        )}
                    </div>
                ) : (
                    <div>
                        {/* PASOS */}
                        {/* <div className="row" id="PASOS-PAGO">
                            <div className="container-breadcrumb breadcrumb-pago">
                                <nav className="w-100" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <div className="row w-100">
                                            <div
                                                style={{ width: "91%" }}
                                                className="d-flex flex-column align-items-start justify-content-center"
                                            >
                                                <span className="bold txt-paso">
                                                    Paso {step} de 3
                                                </span>
                                                {step == 1 && (
                                                    <div className="d-flex flex-column mt-1">
                                                        <span className="txt-titulo-paso">
                                                            Carrito de compras
                                                        </span>
                                                        <small className="txt-descripcion-paso">
                                                            Verifica los
                                                            productos de tu
                                                            carrito
                                                        </small>
                                                    </div>
                                                )}
                                                {step == 2 && (
                                                    <div className="d-flex flex-column mt-1">
                                                        <span className="txt-titulo-paso">
                                                            Datos de pago
                                                        </span>
                                                        <small className="txt-descripcion-paso">
                                                            Llena los siguientes
                                                            datos de la orden
                                                        </small>
                                                    </div>
                                                )}
                                                {step == 3 && (
                                                    <div className="d-flex flex-column mt-1">
                                                        <span className="txt-titulo-paso">
                                                            Confirmar orden
                                                        </span>
                                                        <small className="txt-descripcion-paso">
                                                            Verifica todo el
                                                            detalle de tu pedido
                                                        </small>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </ol>
                                </nav>
                            </div>
                        </div> */}

                        <LoadMask loading={loader} dark blur>
                            <div className="mt-5" id="FORMS-PAGO">
                                {step === 1 && (
                                    <Paso01Form
                                        cambiarCantidad={cambiarCantidad}
                                        onSubmit={this.nextStep}
                                        quitar={quitar}
                                        total={total}
                                        location={this.props.location}
                                    />
                                )}
                                {step === 2 && (
                                    <Paso02Form
                                        previousStep={this.previousStep}
                                        onSubmit={finalizarVenta}
                                        municipios={this.state.municipios}
                                        municipiosChange={this.municipiosChange}
                                        sucursal_tienda_online={
                                            sucursal_tienda_online
                                        }
                                    />
                                )}
                                {step === 3 && (
                                    <Paso03Form
                                        previousStep={this.previousStep}
                                        onSubmit={finalizarVenta}
                                    />
                                )}
                            </div>
                        </LoadMask>
                    </div>
                )}
            </div>
        );
    }
}

export default Pago;
