import { createAction, handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { api } from "api";
import { ToastStore } from "react-toasts";
import Swal from "sweetalert2";
import { getMe } from "./usuario/usuario";

// ------------------------------------
// Constantes
// ------------------------------------

const SUBMIT = "SUBMIT";
const NAME_ERROR = "NAME_ERROR";
const PASS_ERROR = "PASS_ERROR";
const LOGIN_LOADER = "LOGIN_LOADER";
const SUBMIT_ERROR = "SUBMIT_ERROR";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        let canLog = true;
        dispatch({ type: LOGIN_LOADER, loader: true });
        dispatch({ type: SUBMIT_ERROR, submitError: false });
        if (data.email === "" || data.email === undefined) {
            canLog = false;
            dispatch({ type: LOGIN_LOADER, loader: false });
        }
        if (data.password === "" || data.password === undefined) {
            canLog = false;
            dispatch({ type: LOGIN_LOADER, loader: false });
        }
        if (canLog) {
            // Esto debiera hacer una peticion a un API
            api.post("usuarios/token_tienda", data)
                .then((resp) => {
                    if (resp) {
                        localStorage.setItem("token", resp.token);
                        dispatch(getMe());
                        dispatch({ type: SUBMIT, autenticado: true });
                        dispatch({ type: LOGIN_LOADER, loader: false });
                    }
                })
                .catch((e) => {
                    dispatch({ type: LOGIN_LOADER, loader: false });
                    dispatch({ type: SUBMIT_ERROR, submitError: true });
                    const msj = e.error
                        ? e.error
                        : "Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Remocar.";
                    ToastStore.error(
                        "Verifique que ha ingresado bien su nombre de usuario y contraseña."
                    );
                    Swal.fire({
                        type: "error",
                        title: "ERROR",
                        text: msj,
                        icon: "error",
                    });
                });
        } else {
            dispatch({ type: LOGIN_LOADER, loader: false });
            dispatch({ type: SUBMIT, autenticado: false });
        }
    };

export const solicitarPass = (data) => (dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });

    let subdominio = "";
    try {
        const URLactual = window.location;
        subdominio = URLactual.hostname.split(".")[0];
    } catch (error) {
        Swal.fire({
            type: "error",
            title: "ERROR",
            text: "Ha ocurrido un error al obtener el subdominio",
            icon: "error",
        });

        return dispatch({ type: LOGIN_LOADER, loader: false });
    }

    console.log(subdominio);
    api.post("usuarios/pass_reset_mail", { ...data, enTienda: 1, subdominio })
        .then(() => {
            Swal.fire({
                type: "success",
                title: "Éxito",
                text: "Te hemos enviado un correo con los pasos a seguir para restablecer tu contraseña, puede tardar unos minutos para que lo recibas",
                icon: "info",
            });
            dispatch(push("/"));
        })
        .catch(() => {
            Swal.fire({
                type: "error",
                title: "ERROR",
                text: "Ha ocurrido un error, verifica que la dirección de correo que has escrito es correcta y sea la asociada a tu cuenta de Remocar.",
                icon: "error",
            });
        })
        .finally(() => {
            dispatch({ type: LOGIN_LOADER, loader: false });
        });
};

export const restablecerPass = (data) => (dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    api.post("usuarios/pass_reset", data)
        .then(() => {
            Swal.fire({
                type: "success",
                title: "Éxito",
                text: "Tu contraseña a sido restablecida con éxito.",
                icon: "success",
            });
            dispatch(push("/"));
        })
        .catch(() => {
            Swal.fire({
                type: "error",
                title: "ERROR",
                text: "El enlace no es válido o ha expirado.",
                icon: "error",
            });
        })
        .finally(() => {
            dispatch({ type: LOGIN_LOADER, loader: false });
        });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("sucursal");
    dispatch({ type: SUBMIT, autenticado: false });
    window.location.assign("/");
};

export const actions = {
    onSubmit,
    solicitarPass,
    restablecerPass,
    logOut,
};

export const reducers = {
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    },
};

export const initialState = {
    submitError: false,
    autenticado: false,
    loader: false,
    me: {},
};

export default handleActions(reducers, initialState);
