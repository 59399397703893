import home from "./modules/home";
import pago from "./modules/pago/pago";
import { combineReducers } from "redux";
// import login from "./modules/cuenta/login";
import productos from "./modules/productos";
import profile from "./modules/cuenta/profile";
import usuario from "./modules/usuario/usuario";
import register from "./modules/cuenta/register";
import usuarios from "./modules/usuarios/usuarios";
import { reducer as formReducer } from "redux-form";
import { routerReducer as routing } from "react-router-redux";
import orden_online from "./modules/orden_online/orden_online";
import notificaciones from "./modules/notificaciones/notificaciones";
import carrito_compras from "./modules/carrito_compras/carrito_compras";
import login from "./modules/login";
import pedidos_online from "./modules/pedidos_online/pedidos_online";

export default combineReducers({
    pago,
    home,
    // login,
    profile,
    routing,
    usuario,
    register,
    usuarios,
    productos,
    orden_online,
    notificaciones,
    carrito_compras,
    form: formReducer,
    login,
    pedidos_online,
});
