import { api } from "api";
import { push } from "react-router-redux";
import { getMe } from '../usuario/usuario';
import { handleActions } from 'redux-actions';
import history from "../../../utility/history";
import { NotificationManager } from "react-notifications";

const SUBMIT = 'REGISTER_SUBMIT';
const LOADER = 'REGISTER_LOADER';
const endpointRedesSociales = "rest-auth"

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = loader => ({
    type: LOADER,
    loader,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('usuarios_online', data).then(() => {
        history.push("/login");
        NotificationManager.success('Cuenta creada con éxito, puedes iniciar sesión', 'Éxito', 3000);
    }).catch((err) => {
        if (err) {
            NotificationManager.error(
                err.detail || 'No fue posible registrar el usuario, intenta más tarde',
                'ERROR',
                0
            );
        }else {
            NotificationManager.error('No fue posible registrar el usuario, intenta más tarde', 'ERROR', 0);
        }
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const onRegisterSocial = (responseSocial, location, provider) => (dispatch) => {
    dispatch(setLoader(true));
    if(responseSocial.accessToken) {
        api.post(`${endpointRedesSociales}/${provider}`, {access_token: responseSocial.accessToken}, {}, false)
            .catch(err => {

            }).then(resp => {
                if (resp) {
                    //  Se registra el token en el localstorage
                    localStorage.setItem("token", resp.key);

                    //  Se llama la accion para obtener la info del usuario
                    dispatch(getMe())
                    
                    NotificationManager.success('Bienvenido(a) a REMOCAR.GT', 'Éxito', 3000);

                    //  Redireccionamiento al la página donde se ingreso al login
                    if (location.state) {
                        if (location.state.from) {
                            history.push(location.state.from.pathname)
                        }else {
                            history.push("/")
                        }
                    }else {
                        history.push("/")
                    }
                }
            }).finally(() => {
                dispatch(setLoader(false));
            })
        
    }else {
        NotificationManager.error(`No fue posible registrar el usuario con ${provider}, intenta más tarde`, 'ERROR', 0);
        dispatch(setLoader(false));
    }
}

export const logOut = () => (dispatch) => {
    localStorage.removeItem('token');
};


export const actions = {
    onSubmit,
    logOut,
    onRegisterSocial,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
};

export const initialState = {
    loader: false,
};

export default handleActions(reducers, initialState);
